import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import React, { memo, Suspense, useEffect } from 'react';
//import { lazyWithPreload } from "react-lazy-with-preload";
import { LoadingOverlay } from 'ui/components/FullOverlays';
import { preloadTheme } from 'ui/theme/theme.preload';

const App = React.lazy(() => import(/* webpackChunkName: "App" */ "ui/App"));
const StoreProvider = React.lazy(() => import(/* webpackChunkName: "Store" */ "store/utils/provider"));

export const AppContext = memo(() => {

  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  if (!isLoading && !isAuthenticated) {
    loginWithRedirect({ authorizationParams: { redirect_uri: window.location.href } });
    return null;
  }

  return (
    <Suspense fallback={<LoadingOverlay message="Loading UI" />}>
      <StoreProvider>
        <App />
      </StoreProvider>
    </Suspense>
  );
});

//App.preload();
preloadTheme();
